import { loadScript } from '../theme.js';

if (!customElements.get('vimeo-video')) {
  customElements.define('vimeo-video', class VimeoVideo extends HTMLElement {
    constructor() {
      super();
      this.initScript();
    }

    initScript() {
      loadScript('https://player.vimeo.com/api/player.js')
      .then((data) => {
        console.log('script successfully loaded', data);
        this.initVimeo();
      })
      .catch((error) => {
        console.error(error);
      });
    }

    initVimeo() {
      this.vimeoVideo = this;
      this.vimeoVideoSettings = JSON.parse(this.vimeoVideo.querySelector('[vimeo-json-settings]').innerHTML);
      this.vimeoPlaceholder = this.vimeoVideo.querySelector('.vimeo-video__placeholder');

      const mediaQueryList = window.matchMedia(`(min-width: ${this.vimeoVideoSettings['videoBreakpoint']})`);

      this.matchQueryInit(mediaQueryList);
      mediaQueryList.addEventListener('change', this.matchQueryChange.bind(this));

      this.unmuteButton = this.vimeoVideo.querySelector('.vimeo-video__unmute-button');
      if (this.unmuteButton) {
        this.unmuteButton.addEventListener('click', this.enableSound.bind(this));
      }
    }

    matchQueryInit(event) {
      let device = event.matches ? 'Desktop' : 'Mobile';
      if (event.matches) {
        this.setupPlayer(device);
      } else {
        this.setupPlayer(device);
      }
    }

    matchQueryChange(event) {
      this.player.unload();
      let device = event.matches ? 'Desktop' : 'Mobile';
      if (event.matches) {
        this.player.loadVideo(this.vimeoVideoSettings['videoId' + device]);
      } else {
        this.player.loadVideo(this.vimeoVideoSettings['videoId' + device]);
      }
    }

    setupPlayer(device) {
      this.player = new Vimeo.Player(this.vimeoPlaceholder, {
        url: this.vimeoVideoSettings['videoId' + `${device}`],
        autoplay: this.vimeoVideoSettings['autoplay'],
        autopause: this.vimeoVideoSettings['autopause'],
        muted: this.vimeoVideoSettings['muted'],
        background: this.vimeoVideoSettings['background'],
        loop: this.vimeoVideoSettings['loop'],
        title: this.vimeoVideoSettings['title'],
        controls: this.vimeoVideoSettings['controls'],
      });
      this.playPlayer();
    }

    playPlayer() {
      // Make sure the video is paused when loaded in
      this.player.pause();

      this.player.ready().then(
        function () {
          var observer = new IntersectionObserver(
            (entries) => {
              entries.forEach((entry) => {
                this.player.getPaused().then(
                  function (paused) {
                    const fallbackImage = this.querySelector('.vimeo-video__slide-fallback-image');
                    if (!entry.isIntersecting && !paused) {
                      if (fallbackImage) {
                        fallbackImage.style.opacity = 1;
                        fallbackImage.style.visibility = "visible";
                      }
                      this.player.pause();
                    } else if (entry.isIntersecting && paused) {
                      if (fallbackImage) {
                        fallbackImage.style.opacity = 0;
                        fallbackImage.style.visibility = "hidden";
                      }
                      this.player.play();
                    }
                  }.bind(this)
                );
              });
            },
            { threshold: 0.2 }
          );
          observer.observe(this);
        }.bind(this)
      );
    }

    enableSound() {
      this.player.setVolume(1);
      this.unmuteButton.classList.add('hidden');
    }
  });
}
